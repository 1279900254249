export type ThemeMode = 'light' | 'dark' | 'system';

export interface ThemePreferences {
  mode: ThemeMode;
  fontSize: number;
}

export const defaultTheme: ThemePreferences = {
  mode: 'system',
  fontSize: 14,
};

export const THEME_NAMESPACE = 'app';
export const THEME_KEY = 'theme';

// Color scheme media query
export const DARK_SCHEME_QUERY = '(prefers-color-scheme: dark)';

// Cookie configuration
export const THEME_COOKIE_OPTIONS = {
  maxAge: 31536000, // 1 year
  path: '/',
};

export const THEME_COLORS = {
  white: '#ffffff',
  black: '#010717',
  yellow: 'rgb(251, 255, 83)',
  blue: 'rgb(133, 224, 246)',
  purple: 'rgb(103, 111, 244)',
  green: 'rgb(191, 236, 9)',
  pink: 'rgb(255, 170, 240)',
  fuscia: 'rgb(255, 104, 236)',
} as const;

// Vibrant color palette
export const VIBRANT_COLORS = {
  RED: ['#FF3366', '#FF0055', '#FF5577'],
  BLUE: ['#00AAFF', '#0088FF', '#33BBFF'],
  GREEN: ['#00FF88', '#00FF99', '#33FFAA'],
  PURPLE: ['#AA44FF', '#9933FF', '#BB66FF'],
  ORANGE: ['#FF8800', '#FF7711', '#FF9933'],
  PINK: ['#FF44DD', '#FF33CC', '#FF66EE'],
};
